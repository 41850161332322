import axios from 'axios';
import qs from 'qs';

export default {
    makeAccountRequest(context, {url, urlParams = {}, method = 'GET', data = {}}) {
        return axios({
                method: method,
                params: urlParams,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { strictNullHandling: true });
                },
                url: url,
                data: data,
                headers: context.getters.agendaApiHeaders
            })
            .then(response => response.data)
            .catch(error => { throw error.response.data; });
    },

    getAccountData(context, {url, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, method: 'GET', urlParams});
    },

    postAccountData(context, {url, data, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, data, method: 'POST', urlParams});
    },

    updateAccountData(context, {url, data, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, data, method: 'PUT', urlParams});
    },

    deleteAccountData(context, {url, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, method: 'DELETE', urlParams});
    },
}