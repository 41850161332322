export default {
    /**
     * Setting current screen in state.
     * @param {Object} state 
     * @param {String} value 
     */
    setCurrentScreen(state, value) {
        this.state.screen = value;

        return value;
    }
}