<template>
    <v-checkbox-element
        v-model="isChecked"
        @edit="$emit('edit:collaborator', { collaborator })"
        @delete="$emit('destroy:collaborator', { collaborator })"
        color="gray"
        :is-right-in-md="true"
    >
        <template v-slot:next-to-checkbox-text>
            {{ getCollaboratorDisplayName( collaborator ) }}
        </template>
    </v-checkbox-element>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import VCheckboxElement from '@components/Sidebar/Layout/Checkboxes/Checkbox';

    export default {
        components: {
            VCheckboxElement,
        },
        props: {
            collaborator: {
                type: Object,
                required: true
            },
        },
        computed: {
            ...mapState(['calendar']),
            ...mapGetters(['getCollaboratorDisplayName']),
            isChecked: {
                get() {
                    const { collaborators } = this.calendar.filters;
    
                    if (collaborators === null) return false;
                    if(collaborators.length === 0) return true;
    
                    return !!collaborators.find(collId => parseInt(collId) === this.collaborator.id);
                },
                set(value) {
                    this.$emit('update:isChecked', { collaborator: this.collaborator, isChecked: !value })
                }
            }
        },
    }
</script>
