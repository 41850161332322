export default {
    hasCollaborators: (state, getters) => state.calendar.collaborators.length > 0,
    
    connectedCollaborator: (state, getters) => {
        if ( getters.hasCollaborators && getters.hasUser ) {
            return state.calendar.collaborators.find(coll => coll.email == state.user.email);
        }
        
        return null;
    },

    getCollaboratorDisplayName: (state, getters) => (collaborator) => {
        if (getters.connectedCollaborator && getters.connectedCollaborator.email == collaborator.email) {
            return window.App.$t('sidebar.collaborators.labels.me');
        }

        return getters.getCollaboratorFullname(collaborator).capitalizeWords();
    },

    getCollaboratorFullname: (state, getters) => (collaborator) => (collaborator.first_name) + (collaborator.last_name ? ' ' + collaborator.last_name : ''),

    getLabelizedCollaborator: (state, getters) => collaborator => { return { ...collaborator, first_name: getters.getCollaboratorDisplayName(collaborator) }; },
}