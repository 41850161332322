<template>
        <div 
            @touchstart="startTouch"
            @click="handleClick"
            v-click-outside="resetTouchAndFocus"
        >
            <slot></slot>
        </div>
</template>

<script>
    export default {
        data() {
            return {
                isTouch: false,
                isFocus: false,
                wasFocus: false,
            }
        },
        methods: {
            startTouch()
            {
                return this.setTouch(true)
                    .setFocus(true);
            },
            handleClick()
            {
                if (this.isTouchAndWasFocus || ! this.isTouch) {
                    this.$emit('clicked');
                }
            },
            setFocus(value)
            {
                this.wasFocus = this.isFocus;
                this.isFocus = value;

                return this;
            },
            setTouch(value)
            {
                this.isTouch = value;

                return this;
            },
            resetTouchAndFocus()
            {
                return this.setTouch(false)
                    .setFocus(false);
            },
        },
        computed: {
            isTouchAndWasFocus()
            {
                return this.isTouch && this.wasFocus;
            },
        },
    }
</script>
