

export default {
    trustupApiHeaders: state => {
        if ( ! state.token ) {
            return {};
        }

        return {
            'Authorization': state.token.token_type + ' ' + state.token.access_token
        };
    },

    agendaApiHeaders:(state, getters) => {
        let headers = {
            // 'Content-Language': state.locale
        };

        if ( state.token ) {
            headers['X-TrustUp-Authorization'] = state.token.token_type + ' ' + state.token.access_token
        }

        if ( state.user && state.user.jwt ) {
            headers['X-TrustUp-JWT'] = state.user.jwt;
        }

        if ( state.account ) {
            headers['X-Account-UUID'] = state.account.uuid;
        }
        
        return headers;
    },
}