


export default {
    /**
     * Focusing given el or el child based on binding.
     * @param {Node} el
     * @param {Object} binding
     */
    inserted: (el, binding) => {
        const { value, arg } = binding;
        if (value) {
            (arg ? el.querySelector(arg) : el).focus();
        }
    },
}