
export default {
    isAllCollaborators: state => state.calendar.filters.collaborators && state.calendar.filters.collaborators.length === 0,

    isWithoutCollaboratorsOnly: state => state.calendar.filters.collaborators === null,

    eventMatchCollaboratorsFilter: (state, getters) => (event) => {
        if (getters.isAllCollaborators || event.collaborators.length == 0) {
            return true;
        }

        if (getters.isWithoutCollaboratorsOnly) {
            return false;
        }

        return event.collaborators.find(coll => state.calendar.filters.collaborators.find(id => id == coll.id)) ? true : false;
    }
}
