
export default {

    /**
     * Setting state modal. 
     * @param {Object} state 
     * @param {?String} name
     * @returns {?String} New value
     */
    setModal(state, name)
    {
        state.modal = name;

        return name;
    }

}