export default {
    setCollaboratorsFilter(state, collaborators)
    {
        state.calendar.filters.collaborators = collaborators;
    },

    storeCollaboratorsFilter(state, id)
    {
        state.calendar.filters.collaborators.push( id );
    },

    destroyCollaboratorsFilter(state, index)
    {
        state.calendar.filters.collaborators.splice(index, 1);
    },
}