export default {
    setCategories(state, { categories })
    {
        state.calendar.categories = categories;
    },

    storeCategory(state, { category })
    {
        state.calendar.categories.push( category );
    },

    updateCategory(state, { index, category })
    {
        state.calendar.categories.splice(index, 1, category);
    },

    destroyCategory(state, { index })
    {
        state.calendar.categories.splice(index, 1);
    },
}