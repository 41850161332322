
<template>
    <v-sidebar-element
        :title="false"
        :next-to-title-slot="false"
        v-bind="$props"
    >
        <div class="flex items-center">
            <div class="w-1/3">
                <v-spa-loading-button
                    :class-name="getButtonClass('dayGridMonth') + ' rounded-tl rounded-bl'"
                    @clicked="changeView('dayGridMonth')" 
                >
                    <img src="/images/sidebar/month.svg" class="w-auto h-4">
                </v-spa-loading-button>
            </div>

            <div class="w-1/3">
                <v-spa-loading-button
                    :class-name="getButtonClass('timeGridWeek')"
                    @clicked="changeView('timeGridWeek')" 
                >
                    <img src="/images/sidebar/week.svg" class="w-auto h-4">
                </v-spa-loading-button>
            </div>

            <div class="w-1/3">
                <v-spa-loading-button
                    :class-name="getButtonClass('timeGridDay') + ' rounded-tr rounded-br'"
                    @clicked="changeView('timeGridDay')" 
                >
                    <img src="/images/sidebar/day.svg" class="w-auto h-4">
                </v-spa-loading-button>
            </div>
        </div>
    </v-sidebar-element>
</template>

<script>
import VSidebarElement from '@components/Sidebar/Layout/Element'
import { mapState } from 'vuex';


export default {
    components: {
        VSidebarElement,
    },
    props: {
        loading: {
            required: true,
            default() {
                return true;
            },
            type: Boolean,
        },
    },
    data() {
        return {
            button: 'relative flex items-center justify-center h-10 w-full bg-gray-200 outline-none transition-opacity duration-200',
            selected: 'opacity-100',
            notSelected: 'opacity-25 hover:opacity-100'
        }
    },
    methods: {
        closeResponsiveSidebar()
        {
            this.$bus.$emit('~spa:closeResponsiveSidebar');

            return this;
        },
        changeView(viewName)
        {
            this.$bus.$emit('fullCalendar:changeView', viewName);

            return this.closeResponsiveSidebar();
        },
        getButtonClass(viewName)
        {
            return this.calendar.view == viewName ? this.selectedButton : this.notSelectedButton;
        }
    },
    computed: {
        ...mapState(['calendar']),
        selectedButton()
        {
            return `${this.button} ${this.selected}`;
        },
        notSelectedButton()
        {
            return `${this.button} ${this.notSelected}`;
        },
    },
}

</script>

<style>

</style>