
export default {

    /**
     * Toggling modal state property.
     * @param {Object} context 
     * @param {String} name Modal name
     */
    toggleModal(context, name)
    {
        const { dispatch, state } = context;
        
        return dispatch('setModal', state.modal ? null : name); 
    },

    /**
     * Setting state modal to given value.
     * @param {Object} context 
     * @param {?String} name Value  
     */
    setModal(context, name)
    {
        const { commit } = context;

        return commit('setModal', name);
    }

}