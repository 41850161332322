import Vue from 'vue';

require("./_spa");

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import './helpers/modals/ModalRenderer';

window.moment.updateLocale('en', {
    calendar : {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]'
    }
});

window.moment.updateLocale('fr', {
    calendar : {
        lastDay : '[Hier]',
        sameDay : '[Aujourd\'hui]',
        nextDay : '[Demain]'
    }
});

import App from './components/App';
import router from './_router';
import i18n from './_i18n';
import store from './store';

window.App = new Vue({
    el: '#app',
    template: '<App/>',
    components: {
        App,
    },
    i18n,
    store,
    router,
    created() {
        window.addEventListener('online', (event) => {
            window.Toasteo.success(
                this.$t('offline_mode.online')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
        window.addEventListener('offline', (event) => {
            window.Toasteo.warning(
                this.$t('offline_mode.offline')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
    }
}).$mount('#app');
