<template>
    <transition
        enter-active-class="transition-all duration-75 transform"
        leave-active-class="transition-all duration-75 transform"
        :enter-class="'opacity-0 ' + enterClass"
        enter-to-class="translate-x-0 opacity-100"
        leave-class="translate-x-0 opacity-100"
        :leave-to-class="'opacity-0 ' + leaveToClass"
        @after-leave="callStop"
        @enter="callEnter"
    >
        <slot></slot>
    </transition>
</template>

<script>
export default {
    props: {
        show: {
            required: true,
            type: Boolean,
        },
        enter: {
            required: false,
            type: Function,
        },
        direction: {
            required: false,
            type: String,
            default() {
                return 'right';
            }
        },
    },
    methods: {
        callEnter()
        {
            if (this.enter) {
                this.enter();
            }
        },

        callStop()
        {
            setTimeout(() => this.$emit('stop'), 10);
        },
    },
    computed: {
        leaveToClass()
        {
            return this.isRight ? 'translate-x-12' : '-translate-x-12';
        },

        enterClass()
        {
            return this.isRight ? '-translate-x-12' : 'translate-x-12';
        },

        isRight()
        {
            return this.direction == 'right';
        },
    }
}
</script>

<style>
</style>