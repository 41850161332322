let tailwind = require('@deegital/trustup-spa-js-assets/tailwind.js');

tailwind.theme.extend.colors.body = '#FFFFFF';
tailwind.theme.extend.colors.notes = '#FBF2B1';
tailwind.theme.extend.fontSize["2xs"] = '0.675rem';

tailwind.theme.inset = {
    '0': 0,
    'auto': 'auto',
    '1/1': '100%',
    '1/2': '50%',
};

tailwind.theme.extend.minHeight = {
    'screen': 'calc(var(--vh, 1vh) * 100)',
};

tailwind.theme.extend.height.screen = 'calc(var(--vh, 1vh) * 100)';

tailwind.theme.extend.maxHeight.screen = 'calc(var(--vh, 1vh) * 100)';

tailwind.theme.extend.maxWidth['sidebar-smartphone'] = 'calc(100vw - 4rem)';

tailwind.theme.extend.padding.sidebar = tailwind.theme.extend.width.sidebar;

module.exports = tailwind;