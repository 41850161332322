import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export default {
    getTailwindBreakPoint: (state, getters) => (breakpoint) => getters.getTailwindValue(`theme.screens.${breakpoint}`).replace(/[^0-9]/gm, '') * 1,
    getTailwindValue: (state, getters) => keys => {
        const recursive = (value, joinedKeys) => {
            let keys = joinedKeys.split('.');
            const nextValue = value[keys[0]];
            return keys.length == 1 ? nextValue : recursive(nextValue, keys.slice(1).join('.'));
        }

        return recursive(fullConfig, keys);
    },
}