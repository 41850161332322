<template>
    <v-sidebar-checkboxes
        :title="$t('sidebar.categories.title')"
        :loading="loading"
        @next-to-title-click="showCreateModal"
    >
        <v-category
                    v-for="category in calendar.categories"
                    :category="category"
                    :key="category.uuid"
                    @edit:category="showEditModal"
                    @destroy:category="destroyCategory"
                    @update:isChecked="updateIsChecked"
        ></v-category>
    </v-sidebar-checkboxes>
</template>

<script>
    import { mapState } from 'vuex';
    import VCategory from './Categories/Category';
    import VSidebarCheckboxes from "@components/Sidebar/Layout/Checkboxes";

    export default {
        components: {
            VCategory,
            VSidebarCheckboxes,
        },
        props: {
            loading: {
                required: true,
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            updateIsChecked( { category, isChecked } )
            {
                const updateStore = isChecked ? this.$store.dispatch('destroyCategoriesFilter', category.id) : this.$store.dispatch('storeCategoriesFilter', category.id);
                let { query, params, name } = this.$route;
                let newQuery = { ...query, ...{ categories: this.$store.state.calendar.filters.categories } };

                return updateStore.then(() => this.$router.replace({ name, params, query: newQuery  }));
            },

            showEditModal( { category } )
            {
                this.$bus.$emit('category:edit', category);

                return this;
            },

            showCreateModal()
            {
                this.$bus.$emit('category:create');

                return this;
            },

            destroyCategory({ category })
            {
                this.$bus.$emit('category:destroy', category.uuid);

                return this;
            },
        },
        computed: {
            ...mapState(['calendar']),
        },
        created() {
            this.$modalRenderer.add('v-category-modal', 'Sidebar/Categories/FormHandler');
        },
        beforeDestroy()
        {
            this.$modalRenderer.remove('v-category-modal'); 
        }

    }
</script>

<style>

</style>