<template>
    <v-sidebar-element
        v-bind="$props"
        :loader="false"
        :next-to-title-slot="false"
        :title="false"
    >
        <v-spa-loading-button
            font-size=""
            :small="true"
            :color="$spa.css.button.color.white_border"
            @clicked="$bus.$emit('calendar:createEvent')" 
            v-if="! loading"
        >
            <i class="text-xs fas fa-plus"></i>
            {{ $t('sidebar.events.form.buttons.create') }}
        </v-spa-loading-button>
    </v-sidebar-element>
</template>

<script>
    import VSidebarElement from '@components/Sidebar/Layout/Element';

    export default {
        components: {
            VSidebarElement,
        },
        props: {
            loading: {
                required: true,
                type: Boolean,
                default: true,
            }
        }
    }
</script>

<style>

</style>