<template>
    <transition
        enter-active-class="transition duration-200 transform"
        leave-active-class="transition duration-200 transform"
        enter-class="scale-0 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-class="scale-100 opacity-100"
        leave-to-class="scale-0 opacity-0"
    >
        <div v-if="show">
            <div class="flex items-center justify-center ml-2 h-12 w-12 bg-gray-200 rounded-full transition-colors duration-200 group cursor-pointer shadow" :class="additionalClasses" @click="$emit('clicked')">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default() {
                return true;
            }
        },
        backgroundHover: {
            type: String,
            default()
            {
                return 'bg-gray-400';
            }
        },
        background: {
            type: String,
            default() {
                return 'bg-gray-200';
            }
        },
        hover: {
            type: String,
            default() {
                return null;
            }
        },
        lgDisplay: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    computed: {
        backgroundClasses()
        {
            return `${this.background} hover:${this.backgroundHover}`;
        },
        additionalClasses()
        {
            let classes = this.backgroundClasses;
        
            if (this.largeDeviceClasses) {
                classes += ` ${this.largeDeviceClasses}`;
            }

            return classes;
        },
        largeDeviceClasses()
        {
            return this.lgDisplay ? null : 'lg:hidden';
        }
    }
}
</script>