<template>
    <div id="app" class="relative h-screen">
        <div class="absolute inset-0 z-50 p-16" v-if="$store.state.loadingApplication">
            <v-spa-loader></v-spa-loader>
            <p class="font-thin text-center text-gray-700">Loading application...</p>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>    
    import { debounce } from '../helpers/debounce';

    export default {
        name: 'app',

        data() {
            return {
                debouncedResize: null,
            }
        },

        methods: {
            update1vhCssProperty(e)
            {
                document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
            },
        },

        watch: {
            '$route': {
                deep: true,
                handler: function (page) {
                    // if ( this.$store.state.account ) {
                    //     this.$store.dispatch('setVariableInTitle', {variable: 'accountname', value: this.$store.state.account.name});
                    // }

                    // window.Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)});
                }
            }
        },
        created() {
            this.update1vhCssProperty();
            this.debouncedResize = debounce(this.update1vhCssProperty, 500);
            window.addEventListener('resize', this.debouncedResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.debouncedResize);
        },
    }
</script>

<style lang="scss">
    .vm--container.scrollable .vm--modal {
        @media (max-width: calc(theme('screens.md') - 1px)) {
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            width: 100% !important;
            min-height: 100% !important;
        }
        margin-bottom: 0 !important;
        overflow: visible !important;
    }
</style>