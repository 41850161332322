import i18n from "~base/_i18n";

export default {
        /**
     * Set the online status.
     *
     * @param {Object} state Vuex's state
     * @param {String} online
     */
    setOnlineStatus(state, status) {
        if (status == 'offline' || status == false) {
            state.online = false;
        } else {
            state.online = true;
        }
    },

    /**
     * Set the current user.
     *
     * @param {Object} state Vuex's state
     * @param {Object} user
     */
    setUser(state, user) {
        state.user = user;

        // if ( user ) {
        //     window.intercomSettings.user_id = user.id;
        //     window.intercomSettings.user_hash = user.intercom_hash;
        //     window.intercomSettings.name = user.first_name + ' ' + user.last_name;
        //     window.intercomSettings.email = user.email;
        //     window.intercomSettings.created_at = user.created_at;
        //     window.Intercom('update', window.intercomSettings);
        // }
    },

    /**
     * Set the current token.
     *
     * @param {Object} state Vuex's state
     * @param {Object} token
     */
    setToken(state, token) {
        state.token = token;
        window.axios.defaults.headers.common['Authorization'] = state.token.token_type + ' ' + state.token.access_token;
        localStorage.setItem('token', JSON.stringify(token));
    },

    /**
     * Set the token given in the localstorage.
     *
     * @param {Object} state Vuex's state
     * @param {Object} token
     */
    setTokenInLocalStorage(state, token) {
        localStorage.setItem('token', JSON.stringify(token));
    },

    /**
     * Set the current token in the localstorage.
     *
     * @param {Object} state Vuex's state
     * @param {Object} token
     */
    setCurrentTokenInLocalStorage(state)
    {
        localStorage.setItem('token', JSON.stringify(state.token));
    },

    /**
     * Remove the token from the localstorage.
     *
     * @param {Object} state Vuex's state
     */
    removeToken(state)
    {
        state.token = null;
        window.axios.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('token');
    },

    /**
     * Set the account.
     *
     * @param {Object} state Vuex's state
     * @param {Object} account
     */
    setAccount(state, account) {
        state.account = account;

        // if ( account ) {
        //     window.intercomSettings.invoicing_account = account.name;
        //     window.intercomSettings.invoicing_account_uuid = account.uuid;
        //     window.intercomSettings.invoicing_account_created_at = account.created_at;
        //     window.Intercom('update', window.intercomSettings);
        // }
    },

    /**
     * Set the status of the application loading.
     *
     * @param {Object}  state Vuex's state
     * @param {Boolean} status
     */
    setApplicationLoadingStatus(state, status) {
        state.loadingApplication = status;
    },

    setLocale(state, locale) {
        if ( ! locale || ! i18n.availableLocales.includes(locale) ) {
            return;
        }
        
        i18n.locale = locale;
        window.moment.locale(locale);
        state.locale = locale;
    }
}