

export default class keyPress {
    constructor(el)
    {
        this.el = el;
        this.keyDown = {};
        this.addListeners();
    }

    addListeners()
    {
        this.listeners = {};
        return this.addStartListener()
            .addStopListener()
    }

    addStartListener()
    {
        this.listeners.start = e => this.addToKeys(e);
        this.el.addEventListener('keydown', this.listeners.start);

        return this;
    }

    addStopListener()
    {
        this.listeners.end = e => this.handleKeyup(e);
        this.el.addEventListener('keyup', this.listeners.end);

        return this;
    }

    addToKeys(e)
    {
        if (! this.keyDown[e.key]) {
            this.keyDown[e.key] = true;
        }

        return this;
    }

    removeFromKeys(e)
    {
        if (this.keyDown[e.key]) {
            delete this.keyDown[e.key];
        }

        return this;
    }

    getKeyPressed()
    {
        return Object.keys(this.keyDown);
    }

    getCurrentEventName()
    {
        return this.getKeyPressed().sort().join('-');
    }

    getEventName(keys)
    {
        return keys.sort().join('-');
    }

    handleKeyup(e)
    {
        const event = new CustomEvent(this.getCurrentEventName())
        this.el.dispatchEvent(event);

        this.removeFromKeys(e);
    }
}