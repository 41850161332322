import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
  
// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: 'fr',
	fallbackLocale: 'fr',
	silentFallbackWarn: true,
	messages: window.translations
});

export default i18n;