<template>
    <div class="fixed flex z-10 bottom-0 right-0 mr-4 mb-4">
        <v-mobile-button
            :show="showCurrentDateButton"
            :lg-display="true"
            @clicked="setCalendarAsToday"
        >
            <span class="text-xl text-gray-600 group-hover:text-gray-800">{{ currentDate }}</span>
        </v-mobile-button>
        <v-mobile-button
            :show="showAddEventButton"
            background="bg-primary"
            background-hover="bg-primary-dark"
            @clicked="openCreateEventModal"
        >
            <i class="fas fa-plus text-sm text-white"></i>
        </v-mobile-button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VMobileButton from './MobileButtons/MobileButton';

export default {
    components: {
        VMobileButton,
    },
    data() {
        return {
            currentDate: new Date().getDate(),
            loading: true,
        }
    },
    methods: {
        setCalendarAsToday()
        {
            this.$bus.$emit('fullCalendar:today')

            return this;
        },
        openCreateEventModal()
        {
            this.$bus.$emit('calendar:createEvent')

            return this;
        }
    },
    computed: {
        ...mapState(['calendar']),
        showCurrentDateButton()
        {
            return (this.loading || this.calendar.isToday) ?  false : true;
        },
        showAddEventButton()
        {
            return this.loading ? false : true;
        }
    },
    created()
    {
        this.$bus.$on('calendar:loaded', () => this.loading = false)
    }
}
</script>
