<template>
    <v-modal 
        name="events"
        :title="modalTitle"
        :is-edit="isEditForm"
        @before-close="beforeClosingModal" 
        @cancel="closeModal" 
        @save="handleModalSubmit"
        @delete="destroyEvent"
        :saving="form.isLoading()"
        :deleting="isDeleting"
    >
        <form @submit.prevent="handleModalSubmit">
            <v-spa-input
                :form="form" 
                inputName="title" 
                id="edit_event_form_title"
                :displayLabel="false"
                :placeholder="$t('sidebar.events.form.placeholders.title')"
                :className="$spa.css.form.input_full_width"
                v-focus:input="isEditForm ? false : true"
            ></v-spa-input>

            <div class="flex items-center w-full mb-6">
                <i class="mr-2 text-base text-gray-800 far fa-clock md:text-xl sm:mr-4"></i>
                <div class="flex flex-auto">
                    <v-spa-date-picker
                        :form="form"
                        inputName="start_date"
                        id="event_form_start_date"
                        :display-label="false"
                        :placeholder="$t('form.placeholders.date')"
                        containerClassName="mr-2 w-2/3"
                        :popover="{visibility: 'click'}"
                        :locale="$store.state.locale"
                    ></v-spa-date-picker>
                    <v-spa-time-picker
                        :form="form"
                        inputName="start_time"
                        id="event_form_start_time"
                        :displayLabel="false"
                        :placeholder="$t('form.placeholders.time')"
                        text-format="H[h]mm"
                        containerClassName="w-1/3"
                        :minute-interval="this.timePicker.interval"
                        select-class-name="w-full px-3 py-2 select-with-chevron hover:bg-gray-100 cursor-pointer rounded-sm outline-none border border-gray-400 text-black max-w-input text-sm"
                    ></v-spa-time-picker>
                </div>
            </div>

            <div class="flex items-center w-full mb-6">
                <i class="mr-2 text-base text-gray-800 far fa-clock md:text-xl sm:mr-4"></i>
                <div class="flex flex-auto">
                    <v-spa-date-picker
                        :popover="{visibility: 'click'}"
                        :form="form"
                        inputName="end_date"
                        id="event_form_end_date"
                        :display-label="false"
                        :placeholder="$t('form.placeholders.date')"
                        containerClassName="mr-2 w-2/3"
                        :min-date="formEndDateMinimumStartDate"
                    >
                    </v-spa-date-picker>
                    <v-spa-time-picker
                        :form="form"
                        inputName="end_time"
                        id="event_form_end_time"
                        :displayLabel="false"
                        :placeholder="$t('form.placeholders.time')"
                        text-format="H[h]mm"
                        containerClassName="w-1/3"
                        :minute-interval="this.timePicker.interval"
                        :start-time="formEndTimeStartTimeOption"
                        select-class-name="w-full px-3 py-2 select-with-chevron hover:bg-gray-100 cursor-pointer rounded-sm outline-none border border-gray-400 text-black max-w-input text-sm"
                    ></v-spa-time-picker>
                </div>
            </div>

            <div class="flex items-center w-full mb-6">
                <i class="mr-2 text-base text-gray-800 far fa-user md:text-xl sm:mr-4"></i>
                <v-spa-multiselect
                    :form="form"
                    inputName="collaborators"
                    id="event_form_collaborators"
                    :displayLabel="false"
                    :placeholder="$t('sidebar.events.form.placeholders.collaborators')"
                    
                    :options="labelizedCollaborators"
                    track-by="email"
                    :close-on-select="true"
                    option-label="first_name"
                    containerClassName="flex-auto"
                ></v-spa-multiselect>
            </div>

            <div class="flex items-center w-full mb-6">
                <i class="mr-2 text-base text-gray-800 far fa-list-alt md:text-xl sm:mr-4"></i>
                <v-spa-element 
                    :displayLabel="false"
                    id="form_event_category_id" 
                    :form="form" 
                    input-name="form_event_category_id"
                    container-class-name="flex-auto"
                >
                    <select :class="$spa.css.form.select_full_width" v-model="form.category_id">
                        <option
                            :value="null"
                        >
                            {{ $t('sidebar.events.form.categories.empty') }}
                        </option>
                        <option
                            v-for="category in calendar.categories"
                            :value="category.id"
                            :key="category.id"
                        >
                            {{ category.title }}
                        </option>
                    </select>
                </v-spa-element >
            </div>
            
            <v-spa-textarea
                :form="form" 
                inputName="notes"
                class-name="w-full text-black placeholder-gray-500 font-thin px-3 py-2 rounded-sm bg-notes outline-none" 
                id="edit_event_form_notes"
                :displayLabel="false"
                :placeholder="$t('sidebar.events.form.placeholders.notes')"
                default-height="h-24"
                @keyup.native.enter.stop=""
            ></v-spa-textarea>
        </form>
    </v-modal>
</template>

<script>

import VModal from '../Modal';
import Form from '~spa/classes/Form';

import { getNextMomentMatching } from '../../helpers/moment';
import { focus } from '../../directives';

import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        VModal
    },
    props: {

    },
    directives: {
        focus,
    },
    data()
    {
        return {
            form: {},
            formUuid: null,
            isDeleting: false,
            timePicker: {
                interval: 15,
            },
        }
    },
    methods: {
        createEventBasedOnDates( { start, end } )
        {
            return this
                .setDefaultCollaboratorInForm()
                .updateFormDates( start, end )
                .openModal();
        },

        createEvent( )
        {
            return this
                .setDefaultCollaboratorInForm()
                .setDefaultDatesInForm()
                .openModal();
        },

        setDefaultCollaboratorInForm()
        {
            this.form.collaborators = [ this.getLabelizedCollaborator(this.connectedCollaborator) ];

            return this;
        },

        setDefaultDatesInForm()
        {
            const start = getNextMomentMatching(window.moment(), {type: 'minute', value: this.timePicker.interval}, 'hour');
            const end = window.moment(start).add(1, 'hours');

            return this.updateFormDates(start, end);
        },

        editEvent( { event, revert = null } )
        {
            return this
                .updateFormBasedOnEvent( event, revert )
                .openModal();
        },

        updateFormBasedOnEvent( event, revert = null )
        {
            this.updateFormDates( event.start, event.end );

            if (revert) {
                this.form.revert = revert;
            }
            
            this.formUuid = event.extendedProps.uuid;
            this.form.title = event.title;

            this.form.category_id = event.extendedProps.category ? event.extendedProps.category.id : event.extendedProps.category;
            this.form.collaborators = event.extendedProps.collaborators.map(coll => this.getLabelizedCollaborator(coll));
            this.form.notes = event.extendedProps.notes;

            return this;
        },

        updateFormDates( start, end )
        {
            return this
                .updateFormStartDate( start )
                .updateFormEndDate( end );
        },

        updateFormEndDate( end )
        {
            let momentEnd = window.moment( end );
            this.form.end_date = momentEnd.clone().format('YYYY-MM-DD');
            this.form.end_time = momentEnd.clone().format('HH:mm');

            return this;
        },

        updateFormStartDate( start )
        {
            let momentStart = window.moment( start );
            this.form.start_date = momentStart.clone().format('YYYY-MM-DD');
            this.form.start_time = momentStart.clone().format('HH:mm');

            return this;
        },

        resetForm()
        {
            this.form = new Form({
                title: '',
                start_date: null,
                end_date: null,
                start_time: null,
                end_time: null,
                category_id: null,
                collaborators: [],
                notes: null
            });

            this.formUuid = null;
            
            return this;
        },

        getApiFriendlyFormData( formData )
        {
            let { start_date, end_date, start_time, end_time, collaborators, ...formatted } = formData;
            formatted.start_date = `${start_date} ${start_time}`;
            formatted.end_date = `${end_date} ${end_time}`;
            formatted.collaborators_id = collaborators.map(coll => coll.id);

            return formatted;
        },

        openModal()
        {
            this.$modal.show('events');

            return this;
        },

        beforeClosingModal(event)
        {
            if ( ! this.form.success && this.form.revert ) {
                this.$bus.$emit('fullCalendar:revert', this.form.revert)
            }
            this.$bus.$emit('fullCalendar:cancelSelection');
            
            return this.resetForm();
        },

        closeModal()
        {
            this.$modal.hide('events');

            return this;
        },

        handleModalSubmit()
        {
            if( this.form.isLoading() ) {
                return;
            }
            this.form.load();

            return this.isEditForm ? this.updateEvent() : this.storeEvent();
        },

        storeEvent()
        {
            return this.$store.dispatch('storeEvent', {
                event: this.getApiFriendlyFormData(this.form.data())
            })
            .then( ({ data: event }) => {
                window.Toasteo.success(this.$t('sidebar.events.store.success'));
                return this.closeModal();
            } ).catch( err => window.Toasteo.error(this.$t('sidebar.events.store.error')))
            .finally( () => this.form.stop() );
        },

        updateEvent()
        {
            return this.$store.dispatch('updateEvent', {
                uuid: this.formUuid,
                event: this.getApiFriendlyFormData(this.form.data()),
            }).then(( { data: event } ) => {
                window.Toasteo.success(this.$t('sidebar.events.update.success'));
                this.form.success = true;
                return this.closeModal();
            }).catch( err => window.Toasteo.error(this.$t('sidebar.events.update.error')))
            .finally( () => this.form.stop() );
        },

        destroyEvent()
        {
            if( this.isDeleting || ! window.confirm(this.$t('form.delete.explanation')) ) {
                return;
            }

            this.isDeleting = true;

            return this.$store.dispatch('destroyEvent', {
                uuid: this.formUuid,
            }).then( () => {
                window.Toasteo.success(this.$t('sidebar.events.delete.success'));
                return this.closeModal();
            }).catch( err => window.Toasteo.error(this.$t('sidebar.events.delete.error')))
            .finally( () => this.isDeleting = false );
        },
    },
    computed: {
        ...mapState(['calendar']),
        ...mapGetters(['getLabelizedCollaborator', 'connectedCollaborator']),

        isEditForm()
        {
            return this.formUuid ? true : false;
        },
        
        labelizedCollaborators()
        {
            return this.calendar.collaborators.map(coll => this.getLabelizedCollaborator(coll));
        },

        formEndTimeStartTimeOption()
        {
            if (this.form.start_date && this.form.start_time && this.form.end_date && this.form.start_date == this.form.end_date) {
                return window.moment(this.form.start_time, 'HH:mm').add(this.timePicker.interval, 'minutes').format('HH:mm');
            }

            return '00:00';
        },

        formEndMoment()
        {   
            if (this.form && this.form.end_date && this.form.end_time) {
                return window.moment(this.form.end_date+' '+this.form.end_time);
            }

            return null
        },

        formStartMoment()
        {
            if (this.form && this.form.start_date && this.form.start_time) {
                return window.moment(this.form.start_date+' '+this.form.start_time);
            }

            return null
        },

        formEndDateMinimumStartDate()
        {
            if (this.formStartMoment) {
                return this.formStartMoment.clone().format('YYYY-MM-DD');
            }

            return null;
        },
        modalTitle()
        {
            return this.isEditForm ? this.$t('sidebar.events.form.title.edit') : this.$t('sidebar.events.form.title.add');
        },
    },
    watch: {
        formStartMoment(newValue, oldValue) {
            if ( newValue && oldValue && this.formEndMoment ) {
                let diff = this.formEndMoment.diff(oldValue);
                this.updateFormEndDate( newValue.clone().add(diff) );
            }
        },
        
        formEndMoment(newValue) {
            if ( newValue && this.formStartMoment && newValue <= this.formStartMoment ) {
                this.updateFormEndDate( this.formStartMoment.clone().add(this.timePicker.interval, 'minutes') );
            }
        },
    },
    created()
    {
        this
            .resetForm()
            .$bus.$on('calendar:createEvent', this.createEvent)        
            .$bus.$on('calendar:createEventBasedOnDates', this.createEventBasedOnDates)        
            .$bus.$on('calendar:editEvent', this.editEvent)        
            .$bus.$on('calendar:destroyEvent', this.destroyEvent)       
    },
    mounted()
    {
        
    },
    beforeDestroy() {
        
    },
}
</script>

<style lang="scss">
    
</style>