import axios from 'axios';

export default {

    getProfessionals(context, userId)
    {
        return context.dispatch('getData', {
            url: window.url.api.trustup + '/me/professionals',
            key: 'professionals'
        });
    },

    getProfessional(context, {userId, professionalId})
    {
        return context.dispatch('getData', {
            url: window.url.api.trustup + '/me/professionals/' + professionalId,
            key: 'professional'
        });
    },


}