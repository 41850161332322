<template>
    <v-calendar :loading="loadingAll"></v-calendar>
</template>

<script>
    import VAppLayout from './_Layout';
    import VCategories from './Sidebar/Categories';
    import VCollaborators from './Sidebar/Collaborators';
    import VEvents from './Sidebar/Events';
    import VCalendar from './Calendar/Calendar';
    import VSidebarCalendarControls from './Sidebar/Controls';
    import VHeaderCalendarControls from './Calendar/Controls';
    import { initial as initialCategories } from "../config/categories";

    import { mapState, mapGetters } from 'vuex';

    export default {
        components: {
            VAppLayout,
            VCategories,
            VCalendar,
            VSidebarCalendarControls,
            VHeaderCalendarControls,
            VCollaborators,
            VEvents,
        },
        methods: {
            setCategories() 
            {
                return this.$store.dispatch('setCategories');
            },

            setCategoriesFilter()
            {
                return this.$store.dispatch('setCategoriesFilter', this.$route.query.categories);
            },

            storeInitialCategories( actualCategories )
            {
                if (actualCategories.length === 0) {
                    return Promise.all( initialCategories.map(category => this.$store.dispatch('storeCategory', { category })) );
                }

                return Promise.resolve();
            },

            setCollaborators()
            {
                return this.$store.dispatch('setCollaborators');
            },

            setCollaboratorsFilter()
            {
                return this.$store.dispatch('setCollaboratorsFilter', this.$route.query.collaborators);
            },

            storeUserAsCollaborator()
            {
                if (this.connectedCollaborator) {
                    return Promise.resolve();
                }

                let { email, first_name, last_name } = this.$store.state.user;

                return this.$store.dispatch('storeCollaborator', {
                    collaborator: { email, first_name, last_name }
                });
            },

            setEvents() 
            {
                return this.$store.dispatch('setEvents');
            },

            setDefaultCalendarLimits(limits)
            {
                return this.$store.dispatch('setCalendarLimitDates', this.getCalendarNewLimitsBasedOnDate(window.moment()) );
            },

            setAccount()
            {
                if(this.$store.getters.hasAccount){
                    return Promise.resolve(this.$store.state.account);
                }

                return this.$store.dispatch('getAccount', this.$route.params.account)
                    .then(account => this.$store.dispatch('setAccount', account));
            },

            initCollaborators()
            {
                return this.setCollaborators().then(() => Promise.all([
                    this.setCollaboratorsFilter(),
                    this.storeUserAsCollaborator(),
                ])).then(() => this.$store.state.calendar.loading.collaborators = false)
            },

            initCategories()
            {
                return this.setCategories()
                    .then( ({ data: categories }) => Promise.all([
                        this.setCategoriesFilter(),
                        this.storeInitialCategories(categories),
                    ]).then(() => this.$store.state.calendar.loading.categories = false));
            },

            initEvents()
            {
                return this.setDefaultCalendarLimits()
                    .then(() => this.setEvents() )
                    .then(() => this.$store.state.calendar.loading.events = false );
            },

            initCalendar()
            {                
                Promise.all([
                    this.initCategories(),
                    this.initCollaborators(),
                    this.initEvents(),
                ]).then(() => this.$bus.$emit('calendar:loaded'));
            },
        },
        computed: {
            ...mapGetters(['connectedCollaborator', 'getCalendarNewLimitsBasedOnDate']),
            loadingAll()
            {
                return Object.values(this.$store.state.calendar.loading).find(loading => loading == true) ? true : false;
            }
        },
        created() {
            this.initCalendar();
        },
    }
</script>
