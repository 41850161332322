export default {
    setCollaborators(context) {
        return context.dispatch('getAccountData', {
            url: '/api/collaborators',
        })
        .then(data => {
            let { data: collaborators } = data;
            context.commit('setCollaborators', { collaborators: collaborators.sort((coll) => coll.email == context.state.user.email ? -1 : 1) });
            return data;
        } );
    },

    refreshCollaborators(context) {
        return context.dispatch('setCollaborators');
    },

    storeCollaborator(context, { collaborator }) {
        return context.dispatch('postAccountData', {
            url: `/api/collaborators`,
            data: collaborator,
        })
        .then(data => {
            let { data: collaborator } = data;
            context.commit('storeCollaborator', {
                collaborator,
            });
            return data;
        });
    },

    updateCollaborator(context, { uuid, collaborator }) {
        return context.dispatch('updateAccountData', {
            url: `/api/collaborators/${uuid}`,
            data: collaborator
        })
        .then(data => {
            let { data: collaborator } = data;
            context.commit('updateCollaborator', {
                index: context.state.calendar.collaborators.findIndex(coll => coll.uuid == uuid),
                collaborator: collaborator,
            });
            return data;
        });
    },

    destroyCollaborator(context, { uuid }) {
        return context.dispatch('deleteAccountData', {
            url: `/api/collaborators/${uuid}`,
        })
        .then(data => {
            context.commit('destroyCollaborator', {
                index : context.state.calendar.collaborators.findIndex(coll => coll.uuid == uuid),
            });
            return data;
        });
    },
}