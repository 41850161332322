const categories = {
    colors: [
        '#ED69C0',
        '#EB3C89',
        '#AD3F55',
        '#7A5549',
        '#EE6D40',
        '#F2AE54',
        '#F8CB45',
        '#C8B656',
        '#A9CF4B',
        '#5AC47D',
        '#377D50',
        '#9865D5',
        '#333333',
        '#EF7D79',
        '#EDA7EC',
        '#F4B1AF',
        '#754286',
        '#9EADBB',
        '#74A1BA',
        '#2C518C',
        '#72C9C5',
        '#535FD7',
        '#3A228E',
    ],
    initial: [
        {
            title: 'Rendez-vous',
            color: '#ED69C0'
        },
        {
            title: 'Evènements',
            color: '#5AC47D'
        },
        {
            title: 'Chantiers',
            color: '#333333'
        },
        {
            title: 'Tâches',
            color: '#535FD7'
        },
    ]
}

export const { colors, initial } = categories;
export default categories;