<template>
    <v-checkbox-element
        @checkbox-toggle="updateFilters"
        @edit="$emit('edit:category', { category })"
        @delete="$emit('destroy:category', { category })"
        :initial-checkbox-state="isChecked()"
        :color="category.color"
        :is-right-in-md="false"
    >
        <template v-slot:next-to-checkbox-text>
            {{ category.title }}
        </template>
    </v-checkbox-element>
</template>

<script>
    import { mapState } from 'vuex';
    import VCheckboxElement from '@components/Sidebar/Layout/Checkboxes/Checkbox';

    export default {
        components: {
            VCheckboxElement,
        },
        props: {
            category: {
                type: Object,
                required: true
            },
        },
        data() {
            return {

            }
        },
        methods: {
            isChecked()
            {
                if (this.calendar.filters.categories === null) {
                    return false;
                }

                if (this.calendar.filters.categories.length === 0) {
                    return true;
                }
                
                return this.calendar.filters.categories.find(id => id == this.category.id) ? true : false;
            },
            updateFilters(values)
            {
                const { old } = values;
                this.$emit('update:isChecked', { category: this.category, isChecked: old })
            },
        },
        computed: {
            ...mapState(['calendar']),
        },
    }
</script>
