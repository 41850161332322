export default {
    findCategoriesFilterIndex: (state, getters) => (id) => state.calendar.filters.categories.findIndex(catId => catId == id),

    isAllCategories: (state, getters) => state.calendar.filters.categories && state.calendar.filters.categories.length === 0,

    isUncategorizedOnly: (state, getters) => state.calendar.filters.categories === null,

    eventMatchCategoriesFilter: (state, getters) => (event) => {
        if ( (! event.category) || getters.isAllCategories ) {
            return true;
        }

        if (getters.isUncategorizedOnly) {
            return false;
        }

        return state.calendar.filters.categories.find(id => id == event.category.id) ? true : false;
    }
}