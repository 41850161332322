<template>
    <div class="account-base">
        <layout>
            <template slot="content">
                <div :class="{'h-full bg-body py-12 px-8': ! $store.state.layout.container}" v-if="loading">
                    <v-spa-panel panel-height="auto" :loading="loading"></v-spa-panel>
                </div>
                <div class="relative h-full" v-if="! loading && $store.state.account && $store.state.account.uuid">
                    <router-view :key="'account-' + $store.state.account.uuid + '-' + $store.state.account.updated_at + '-' + $route.fullPath"></router-view>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import Layout from '~base/components/_Layout';

    export default {
        components: {
            'layout': Layout
        },
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            getAccount(uuid)
            {
                return new Promise((resolve, reject) => {
                    this.loading = true;
                    this.$store.dispatch('getAccount', uuid)
                        .then((account) => {
                            this.$store.dispatch('setAccount', account);
                            resolve();
                        })
                        .catch(error => {
                            console.log(error);
                            window.Toasteo.error('Account #' + uuid + ' not found.');
                            this.$router.push({name: 'homepage'});
                        });
                });
            },

        },
        computed: {
            ...mapState(['account']),
        },
        created() {            
            if (this.account) {
                return this.loading = false;
            }

            this.getAccount(this.$route.params.account)
                .then(() => this.loading = false);
        }
    }
</script>
