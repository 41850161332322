<template>
    <v-sidebar-element
        v-bind="$props"
        v-on="$listeners"
        :loader="true"
        class="md:w-1/2 lg:w-full md:px-4 lg:px-0"
    >
        <template v-slot:next-to-title-text>
            {{ $t('sidebar.categories.form.buttons.create') }}
        </template>
        <slot></slot>

    </v-sidebar-element>
</template>

<script>

    import VSidebarElement from '@components/Sidebar/Layout/Element';

    export default {
        components: {
            VSidebarElement,
        },
        props: {
            loading: {
                required: true,
                type: Boolean,
                default: true,
            },
            title: {
                required: true,
                type: String,
            }
        },
        data() {
            return {
                
            }
        },
        methods: {

        },

    }
</script>

<style>

</style>