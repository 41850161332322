
/**
 * Telling if given date is included in given dates range.
 * @param {Date} date Date we are searching for.
 * @param {Date} startDate Range start date.
 * @param {Date} endDate Range end date.
 * @returns {Boolean}
 */
export function isDateInRange(date, startDate, endDate, strictEnd = true)
{
    return ( date >= startDate ) && ( strictEnd ? (date < endDate) : (date <= endDate) );
}