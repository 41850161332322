export default {
    /**
     * Setting current screen size
     * @param {object} context 
     * @param {String} value 
     */
    setCurrentScreen(context, value) {
        const { commit } = context;
        return commit('setCurrentScreen', value);
    }
}