import CategoriesMutations from "./mutations/categories";
import CollaboratorsMutations from "./mutations/collaborators";
import EventsMutations from "./mutations/events";
import FiltersMutations from './mutations/filters';
import BaseMutations from './mutations/base'
import CalendarMutations from './mutations/calendar';
import ScreenMutations from './mutations/screen';
import ModalMutations from './mutations/modal';

export default {
    ...BaseMutations,
    ...CategoriesMutations,
    ...CollaboratorsMutations,
    ...EventsMutations,
    ...FiltersMutations,
    ...CalendarMutations,
    ...ScreenMutations,
    ...ModalMutations,
};
