
/**
 * Round given value based on given parameters.
 * @param {Number} value Value to round.
 * @param {Number} afterCommaNumbers Numbers desired after comma.
 * @returns {Number} Rounded number
 */
const round = (value, afterCommaNumbers = 2) => 
{
    const multiplier = Math.pow(10, afterCommaNumbers);

    return Math.round( value * multiplier ) / multiplier;
}

export { round };