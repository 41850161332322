<template>
    <v-checkbox-element
        @edit="$emit('edit:category', { category })"
        @delete="$emit('destroy:category', { category })"
        :color="category.color"
        :is-right-in-md="false"
        v-model="isChecked"
    >
        <template v-slot:next-to-checkbox-text>
            {{ category.title }}
        </template>
    </v-checkbox-element>
</template>

<script>
    import { mapState } from 'vuex';
    import VCheckboxElement from '@components/Sidebar/Layout/Checkboxes/Checkbox';

    export default {
        components: {
            VCheckboxElement,
        },
        props: {
            category: {
                type: Object,
                required: true
            },
        },
        computed: {
            ...mapState(['calendar']),
            isChecked: {
                get() {
                    const { categories } = this.calendar.filters;
    
                    if (categories === null) return false;
                    if(categories.length === 0) return true;
    
                    return !!categories.find(catId => parseInt(catId) === this.category.id);
                },
                set(value) {
                    this.$emit('update:isChecked', { category: this.category, isChecked: !value })
                }
            }
        },
    }
</script>
