<template>
    <div class="w-full pt-2 pb-4">
        <div class="flex flex-wrap items-center justify-between pb-3" v-if="title">
            <span class="text-lg font-bold text-gray-600 lg:text-base">{{ title }}</span>
            <slot name="next-to-title" v-if="nextToTitleSlot">
                <span v-if="! loading" @click="$emit('next-to-title-click')" class="font-thin text-gray-500 cursor-pointer text-md lg:text-sm">
                    <slot name="next-to-title-text"></slot>
                </span>
            </slot>
        </div>
        <div class="relative w-full">
            <div class="relative w-full py-10" v-if="loading && loader">
                <v-spa-loader></v-spa-loader>
            </div>
            <transition
                enter-active-class="transition duration-300 transform"
                enter-class="-translate-x-2 opacity-0"
            >
                <div v-if="! loading">
                    <slot></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                required: true,
                type: Boolean,
                default: true,
            },
            loader: {
                required: false,
                type: Boolean,
                default: true,
            },
            title: {
                required: true,
                type: [String, Boolean],
            },
            nextToTitleSlot: {
                required: false,
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            
        },

    }
</script>

<style>

</style>