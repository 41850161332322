<template>
    <v-touch-confirm @clicked="toggleCheckbox">
        <div 
            class="relative flex items-center px-8 py-2 cursor-pointer group lg:px-4 bg-sidebar hover:bg-gray-100"
            :class="isRightInMd ? 'md:pl-4 md:pr8' : 'md:pl-8 md:pr-4'"
        >
            <div class="flex items-center justify-center flex-none w-6 h-6 mr-2 border-2 rounded lg:h-5 lg:w-5" 
                :style="{
                    borderColor: this.color,
                    backgroundColor: value ? this.color : '',
                }"
                @click.prevent.stop="toggleCheckbox"
            >
                <i class="text-white fas fa-check text-md lg:text-sm" v-if="value"></i>
            </div>

            <slot name="next-to-checkbox">
                <span class="flex-auto text-gray-700 text-md lg:text-sm">
                    <slot name="next-to-checkbox-text">
                        
                    </slot>
                </span>  
            </slot>
            
            <div 
                class="absolute right-0 hidden h-full px-2 mr-4 bg-gray-100 md:mr-0 group-hover:flex"
                :class="isRightInMd  ? 'md:mr-4 lg:mr-0' : 'md:mr-0'"
            >
                <div 
                    class="flex items-center px-2 text-gray-500 transition-colors duration-200 cursor-pointer hover:text-gray-700" 
                    @click.prevent.stop="$emit('edit')"
                >
                    <i class="fas fa-pencil-alt text-md lg:text-sm"></i>
                </div>
                <div 
                    class="flex items-center px-2 text-gray-500 transition-colors duration-200 cursor-pointer hover:text-gray-700" 
                    @click.prevent.stop="$emit('delete')"
                >
                    <i class="fas fa-trash text-md lg:text-sm"></i>
                </div>
            </div>
        </div>
    </v-touch-confirm>
</template>

<script>

    import VTouchConfirm from '@components/Helpers/TouchConfirm';

    export default {
        components: {
            VTouchConfirm,
        },
        props: {
            color: {
                type: String,
                required: false,
                default() {
                    return 'gray';
                }
            },
            value: {
                type: Boolean,
                required: true,
            },
            isRightInMd: {
                type: Boolean,
                required: true,
                default() {
                    return true;
                },
            },
        },
        data() {
            return {
                isTouch: false,
                isFocus: false,
                wasFocus: false,
            }
        },
        methods: {
            toggleCheckbox()
            {
                this.$emit('input', !this.value);

                return this;
            },
            startTouch()
            {
                return this.setTouch(true)
                    .setFocus(true);
            },
            handleClick()
            {
                if (this.isTouchAndWasFocus || ! this.isTouch) {
                    return this.toggleCheckbox();
                }
            },
            setFocus(value)
            {
                this.wasFocus = this.isFocus;
                this.isFocus = value;

                return this;
            },
            setTouch(value)
            {
                this.isTouch = value;

                return this;
            },
            resetTouchAndFocus()
            {
                return this.setTouch(false)
                    .setFocus(false);
            },
            clickedOutside()
            {
                return this.resetTouchAndFocus();
            }
        },
        computed: {
            isTouchAndWasFocus()
            {
                return this.isTouch && this.wasFocus;
            },
        },
        created() {},
    }
</script>
