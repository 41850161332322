export default {
    setEvents(context) {
        return context.dispatch('getAccountData', {
            url: '/api/events',
            urlParams: {
                start_at: context.getters.calendarLimitsToString.start,
                end_at: context.getters.calendarLimitsToString.end,
            }
        })
        .then(data => {
            let { data: events } = data
            context.commit('setEvents', { events });
            return data;
        });
    },

    refreshEvents(context)
    {
        return context.dispatch('setEvents');
    },

    storeEvent(context, { event }) {
        return context.dispatch('postAccountData', {
            url: `/api/events`,
            data: event,
        })
        .then(data => {
            let { data: event } = data;
            context.commit('storeEvent', {
                event,
            });
            return data;
        });
    },

    updateEvent(context, { uuid, event }) {
        return context.dispatch('updateAccountData', {
            url: `/api/events/${uuid}`,
            data: event
        })
        .then(data => {
            let { data: event } = data;
            context.commit('updateEvent', {
                index: context.getters.findEventIndex( event ),
                event: event,
            });
            return data;
        });
    },

    destroyEvent(context, { uuid }) {
        return context.dispatch('deleteAccountData', {
            url: `/api/events/${uuid}`,
        })
        .then(data => {
            context.commit('destroyEvent', {
                index : context.getters.findEventIndexByUuid(uuid)
            });
            return data;
        });
    },
}