export default {
    setCategoriesFilter(state, categories)
    {
        state.calendar.filters.categories = categories;
    },

    storeCategoriesFilter(state, id)
    {
        state.calendar.filters.categories.push( id );
    },

    destroyCategoriesFilter(state, index)
    {
        state.calendar.filters.categories.splice(index, 1);
    },
}