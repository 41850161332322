const throttle = (callback, delay) => {
    let timer;
    let lastExec;
    let currentExec;
    let context;
    let args;
    
    const setCurrentExec = () => currentExec = new Date().getTime();
    
    const cancel = () => clearInterval(timer);
    
    const shouldCancelLastCall = () => lastExec && currentExec < lastExec + delay;
    
    const setCurrentExecAsLastExec = () => lastExec = currentExec;
    
    const launchCallback = () => callback.apply(context, args);
    
    const setTimer = () => timer = setTimeout(() => {
        setCurrentExecAsLastExec();
        launchCallback();
    }, delay);

    const force = () => {
        cancel();
        launchCallback();
    }
    
    let throttled = function() {
        context = this;
        args = arguments;
        setCurrentExec();

        if ( shouldCancelLastCall() ) {
            cancel();
            setTimer();
        } else {
            setCurrentExecAsLastExec();
            launchCallback();
        }
    }

    throttled.cancel = cancel;
    throttled.force = force;
    
    return throttled;
}

export { throttle };