<template>
    <layout>
        <div class="p-8">
            <div class="relative p-16 bg-white shadow" v-if="loading">
                <v-spa-loader></v-spa-loader>
            </div>
            <div v-else>
                <div v-if="professional">
                    <h3 class="mb-4 text-base text-black">{{ professional.company }}</h3>
                    <div
                        class="flex items-center p-6 mb-4 bg-white shadow cursor-pointer hover:shadow-md"
                        v-for="account in professional.accounts"
                        :key="account.uuid"
                        @click.prevent="selectAccount(account)"
                    >
                        <div v-if="account.logo" class="w-16 h-16 mr-4 bg-gray-400 bg-center bg-no-repeat bg-contain rounded" :style="{'background-image': `url(${account.logo})`}"></div>
                        <span class="font-thin text-gray-600">
                            <template v-if="account.company">{{ account.company }}</template>
                            <template v-else>{{ account.name }}</template>
                            <span class="block text-xs text-gray-400">
                                {{ account.version }}
                            </span>
                        </span>
                        <span class="px-1 ml-4 text-xs text-white bg-orange-500 rounded py-1/2" v-if="account.mode === 'test'">
                            Test
                        </span>
                        <div class="flex-1"></div>
                        <i class="text-2xl text-gray-600 fas fa-arrow-circle-right"></i>
                    </div>
                </div>
                <div class="flex flex-wrap" v-else>
                    <div class="w-full px-4 md:w-1/2 lg:w-1/3" v-for="professional in professionals" :key="professional.id">
                        <div class="p-4 text-center bg-white shadow cursor-pointer hover:shadow-md group" @click.prevent="selectProfessional(professional)">
                            <div class="block w-32 h-32 mx-auto mb-4 bg-center bg-no-repeat bg-contain" :style="{'background-image': 'url('+professional.logo+')'}"></div>
                            <span class="relative px-6 font-thin text-gray-600 group-hover:text-gray-800">
                                {{ professional.company }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
​
<script>
    import Layout from '../_Layout';
    import mixin from '~spa/components/_Table/index-mixin.js';

    export default {
        components: {
            'layout': Layout
        },

        data() {
            return {
                professionals: [],
                professional: null
            }
        },

        computed: {
            hasAccount() {
                for ( let i = 0; i < this.professionals.length; i++ ) {
                    if ( this.professionals[i].accounts.length > 0 ) {
                        return true;
                    }
                }
                
                return false;
            },
            professionalHasOnlyOneAccount()
            {
                return this.professional ? this.professional.accounts.length == 1 : false;
            },
        },

        mixins: [mixin],

        methods: {
            selectProfessional(professional)
            {
                this.professional = professional;

                if ( this.professionalHasOnlyOneAccount ) {
                    return this.selectProfessionalFirstAccount();
                }

                return this;
            },

            selectAccount(account)
            {
                this.$store.commit('setAccount', account);
                this.$router.push({name: 'account.homepage', params: { uuid: account.uuid }});

                return this;
            },

            selectProfessionalFirstAccount()
            {
                return this.selectAccount(this.professional.accounts[0]);
            },
        },

        created() {
            this.$store.commit('setAccount', null);

            this.$store.dispatch('getUserActiveAccounts', this.$store.state.user.id)
                .then((professionals) => {
                    this.professionals = professionals;

                    if ( this.professionals.length == 0 || ! this.hasAccount ) {
                        return this.$router.push({name: 'register'});
                    }

                    if ( this.$store.state.user.default_professional ) {
                        let professional = this.professionals.find(professional => professional.id === this.$store.state.user.default_professional.id);
                        if ( ! professional ) {
                            return this.$router.push({name: 'register'});
                        }

                        this.selectProfessional(professional);
                    }
                    
                    this.loading = false;
                });
        },
        beforeRouteUpdate (to, from, next) {  
            if ( this.professionalHasOnlyOneAccount ) {
                return;
            }

            next();
        }
    }
</script>