<template>
    <v-sidebar-checkboxes
        :title="$t('sidebar.collaborators.title')"
        :loading="loading"
        @next-to-title-click="showCreateModal"
    >
        <v-collaborator 
            v-for="collaborator in calendar.collaborators"
            :collaborator="collaborator"
            :key="collaborator.uuid"
            @edit:collaborator="showEditModal"
            @destroy:collaborator="destroyCollaborator"
            @update:isChecked="updateIsChecked"
        ></v-collaborator>
    </v-sidebar-checkboxes>     
</template>

<script>
    import { mapState } from 'vuex';
    import VCollaborator from './Collaborators/Collaborator';
    import VSidebarCheckboxes from "@components/Sidebar/Layout/Checkboxes";
    
    import Form from '~spa/classes/Form';

    export default {
        components: {
            VCollaborator,
            VSidebarCheckboxes,
        },
        props: {
            loading: {
                required: true,
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {

            };
        },
        methods: {
            
            updateIsChecked( { collaborator, isChecked } )
            {
                const updateStore = isChecked ? this.$store.dispatch('destroyCollaboratorsFilter', collaborator.id) : this.$store.dispatch('storeCollaboratorsFilter', collaborator.id);
                let { query, params, name } = this.$route;
                let newQuery = { ...query, ...{ collaborators: this.$store.state.calendar.filters.collaborators } };

                return updateStore.then(() => this.$router.replace({ name, params, query: newQuery  }));
            },

            showEditModal( { collaborator } )
            {
                this.$bus.$emit('collaborator:edit', collaborator);

                return this;
            },

            showCreateModal()
            {
                this.$bus.$emit('collaborator:create');

                return this;
            },

            destroyCollaborator( { collaborator } )
            {
                this.$bus.$emit('collaborator:destroy', collaborator.uuid);

                return this;
            }
        },
        computed: {
            ...mapState(['calendar']),
        },
        created()
        {
            this.$modalRenderer.add('v-collaborator-modal', 'Sidebar/Collaborators/FormHandler');
        },

        beforeDestroy()
        {
            this.$modalRenderer.remove('v-collaborator-modal');
        },
    }
</script>

<style>

</style>