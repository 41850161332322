<template>
    <v-checkbox-element
        @checkbox-toggle="updateFilters"
        @edit="$emit('edit:collaborator', { collaborator })"
        @delete="$emit('destroy:collaborator', { collaborator })"
        :initial-checkbox-state="isChecked()"
        color="gray"
        :is-right-in-md="true"
    >
        <template v-slot:next-to-checkbox-text>
            {{ getCollaboratorDisplayName( collaborator ) }}
        </template>
    </v-checkbox-element>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import VCheckboxElement from '@components/Sidebar/Layout/Checkboxes/Checkbox';

    export default {
        components: {
            VCheckboxElement,
        },
        props: {
            collaborator: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
            }
        },
        methods: {
            isChecked()
            {
                if (this.calendar.filters.collaborators === null) {
                    return false;
                }

                if (this.calendar.filters.collaborators.length === 0) {
                    return true;
                }
                
                return this.calendar.filters.collaborators.find(id => id == this.collaborator.id) ? true : false;
            },
            updateFilters(values)
            {
                const { old } = values;
                this.$emit('update:isChecked', { collaborator: this.collaborator, isChecked: old })
            },
        },
        computed: {
            ...mapState(['calendar']),
            ...mapGetters(['getCollaboratorDisplayName']),
        },
    }
</script>
