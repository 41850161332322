import { throttle } from '../helpers/throttle';
import { round } from '../helpers/numbers';

export default class Zoom {
    /**
     * Initializing zoom listeners for given HTML element.
     * @param {Node} el HTML element where zoom should be implemented.
     * @param {Number} timeBetweenCalculation Milliseconds between zoom calculation.
     */
    constructor( el, timeBetweenCalculation = 300 ) 
    {
        this.el = el;
        this.listeners = {
            start: null,
            inProgress: null,
            end: null,
        } 
        this.throttledCalculation = throttle(this.calculate, timeBetweenCalculation);
        this.initData()
            .addListeners();
    }

    /**
     * Resetting dynamics data.
     * @chainable
     * @returns {Zoom}
     */
    resetData()
    {
        this.distance = {
            current: null,
            previous: null,
            initial: null,
        }
        this.zoom = {
            current: null,
            initial: null,
        }
        this.isZooming = false;

        return this;
    }

    /**
     * Initializing dynamics data.
     * @chainable
     * @returns {Zoom}
     */
    initData()
    {
        return this.resetData();
    }

    /**
     * Adding listeners to HTML element.
     * @chainable
     * @returns {Zoom}
     */
    addListeners() 
    {
        return this.addStartListener()
            .addInProgressListener()
            .addEndListener()
    }

    /**
     * Adding listener to catch when zoom starts.
     * @chainable
     * @returns {Zoom}
     */
    addStartListener()
    {
        this.listeners.start = e => this.handleMovementStart(e);
        this.el.addEventListener('touchstart', this.listeners.start);

        return this;
    }

    /**
     * Handling movement start.
     * @param {TouchEvent} e Touchstart event.
     * @chainable
     * @returns {Zoom}
     */
    handleMovementStart(e)
    {
        // this.events.push(e);
        if (this.isEventConcerningTwoFingers(e)) {
            this.isZooming = true;
            this.el.dispatchEvent(new Event('zoomStart'));
        }

        return this;
    }

    /**
     * Checking that given touch event is using 2 fingers.
     * @param {TouchEvent} e TouchEvent to check.
     */
    isEventConcerningTwoFingers(e)
    {
        return e.touches.length == 2;
        // return this.events.length == 2;
    }

    /**
     * Preventing event and stopping its propagation.   
     * @param {Event} e Event.
     */
    stopEvent(e)
    {
        e.stopPropagation();
        e.preventDefault();

        return this;
    }

    /**
     * Adding listener to catch when zoom is in progress.
     * @chainable
     * @returns {Zoom}
     */
    addInProgressListener()
    {
        this.listeners.inProgress = e => this.handleMovementInProgress(e);
        this.el.addEventListener('touchmove', this.listeners.inProgress);

        return this;
    }

    /**
     * Handling zoom in progress.
     * @param {TouchEvent} e Touchend event.
     * @chainable
     * @returns {Zoom}
     */
    handleMovementInProgress(e)
    {
        if (this.isZooming) {
            this.stopEvent(e)
            this.throttledCalculation(e);
        }

        return this;
    }

    /**
     * Calculating distance between event touches and firing 'zoomInProgress' customEvent.
     * @param {TouchEvent} e Touchmove event.
     * @chainable
     * @returns {Zoom}
     */
    calculate(e)
    {
        const distance = this.getDistanceBetweenCoordinates( e.touches[0], e.touches[1])
        this
            .setDistanceAsCurrent( distance )
            .setZooms();

        const event = new CustomEvent('zoomInProgress', {
            detail: {
                distance: this.distance,
                zoom: this.zoom,
            }
        });
        this.el.dispatchEvent(event);
        
        return this.setDistanceAsPrevious( distance );
    }

    /**
     * Getting distance between 2 given touches.
     * @param {Touch} a 
     * @param {Touch} b
     * @returns {Number} Distance.
     */
    getDistanceBetweenCoordinates(a, b)
    {
        return Math.sqrt( ( Math.pow( (a.screenX - b.screenX), 2 ) + Math.pow( (a.screenY - b.screenY), 2 ) ) );
    }

    /**
     * Setting given number as previous.
     * @param {Number} distance 
     * @chainable
     * @returns {Zoom}
     */
    setDistanceAsPrevious( distance )
    {
        this.distance.previous = distance;

        return this;
    }

    /**
     * Setting given distance as initial.
     * @param {Number} distance
     * @chainable
     * @returns {Zoom}
     */
    setDistanceAsInitial( distance )
    {
        this.distance.initial = distance;

        return this;
    }

    /**
     * Setting given distance as current.   
     * @param {Number} distance 
     * @chainable
     * @returns {Zoom}
     */
    setDistanceAsCurrent( distance )
    {
        if (! this.distance.previous) {
            this.setDistanceAsPrevious(distance);
        }

        if (! this.distance.initial) {
            this.setDistanceAsInitial(distance);
        }

        this.distance.current = distance;

        return this;
    }   

    /**
     * Setting internal zooms property based on internal distances.
     * @chainable
     * @returns {Zoom}
     */
    setZooms()
    {
        return this
            .setInitialZoom()
            .setCurrentZoom();
    }

    /**
     * Setting internal initial zoom based on internal distances.
     * @chainable
     * @returns {Zoom}
     */
    setInitialZoom()
    {
        this.zoom.initial = round(this.distance.current / this.distance.initial);

        return this;
    }

    /**
     * Setting internal current zoom based on internal distances.
     * @chainable
     * @returns {Zoom}
     */
    setCurrentZoom()
    {
        this.zoom.current = round(this.distance.current / this.distance.previous);

        return this;
    }

    /**
     * Adding listener to catch when movement stops.
     * @chainable
     * @returns {Zoom}
     */
    addEndListener()
    {
        this.listeners.end = e => this.handleMovementStop(e);
        this.el.addEventListener('touchend', this.listeners.end);

        return this;
    }

    /**
     * Handling movement end.
     * @param {Object} e Touchend event.
     * @chainable
     * @returns {Zoom}
     */
    handleMovementStop(e)
    {
        if (this.isZooming) {
            this.throttledCalculation.cancel();
            this.el.dispatchEvent(new Event('zoomStop'));
            this.resetData();
        }

        return this;
    }

    /**
     * Removing zoom listeners for element.
     * @chainable
     * @returns {Zoom}
     */
    removeListeners()
    {
        return this.removeStartListener()
            .removeInProgressListener()
            .removeStopListener();
    }

    /**
     * Removing movement start listener for element.
     * @chainable
     * @returns {Zoom}
     */
    removeStartListener()
    {
        this.el.removeEventListener('touchstart', this.listeners.start);

        return this;
    }

    /**
     * Removing in progress movement listener for element.
     * @chainable
     * @returns {Zoom}
     */
    removeInProgressListener()
    {
        this.el.removeEventListener('touchmove', this.listeners.inProgress);

        return this;
    }

    /**
     * Removing movement stop listener for element.
     * @chainable
     * @returns {Zoom}
     */
    removeStopListener()
    {
        this.el.removeEventListener('touchend', this.listeners.end);

        return this;
    }

}