export default {
    setCollaborators(state, { collaborators })
    {
        state.calendar.collaborators = collaborators;
    },

    storeCollaborator(state, { collaborator })
    {
        state.calendar.collaborators.push( collaborator );
    },

    updateCollaborator(state, { index, collaborator })
    {
        state.calendar.collaborators.splice(index, 1, collaborator);
    },

    destroyCollaborator(state, { index })
    {
        state.calendar.collaborators.splice(index, 1);
    },
}