import Vue from 'vue';

class ModalRenderer {
    constructor()
    {
        this.modals = [];
    }

    add(name, path, props = {})
    {
        if ( this.has(name) ) {
            return;
        }


        this.modals.push({
            name,
            path, 
            props,
        })
    }

    remove(name) {
        this.modals.splice(this.findModalIndex(name), 1);
    }

    has(name)
    {
        return this.findModalIndex(name) === -1 ? false : true;
    }

    findModalIndex(name)
    {
        return this.modals.findIndex(modal => modal.name == name);
    }
}

const renderer = new ModalRenderer;

Vue.prototype.$modalRenderer = renderer;