<template>
    <div class="app-layout">
        <v-spa-layout
            ref="layout"
            responsive-menu-icon="/spa/close.svg"
            @toggle-sidebar="handleSidebar"
            @show-sidebar="handleSidebar"
            @hide-sidebar="handleSidebar"
            :display-responsive-menu-on-desktop="false"
        >
            <div slot="header-next-to-title">
                <v-header-calendar-controls></v-header-calendar-controls>
            </div>
            <v-app-layout-responsive-sidebar ref="responsiveSidebar" slot="header-sidebar"></v-app-layout-responsive-sidebar>
            <v-app-layout-sidebar ref="sidebar" slot="sidebar" v-if="display.sidebar"></v-app-layout-sidebar>
            <v-app-layout-content ref="content" slot="content">
                <slot name="content"></slot>
            </v-app-layout-content>
        </v-spa-layout>
        <v-mobile-buttons></v-mobile-buttons>
        <v-modals></v-modals>
    </div>
</template>

<script>

    import VHeaderCalendarControls from './Calendar/Controls';
    import VModals from '~base/helpers/modals/Modals';
    import VMobileButtons from './Calendar/MobileButtons';
    import Sidebar from './_Layout/Sidebar';
    import ResponsiveSidebar from './_Layout/ResponsiveSidebar';
    import Content from './_Layout/Content';
    
    export default {
        components: {
            VHeaderCalendarControls,
            VMobileButtons,
            VModals,
            'v-app-layout-responsive-sidebar': ResponsiveSidebar,
            'v-app-layout-sidebar': Sidebar,
            'v-app-layout-content': Content
        },
        data() {
            return {
                display: {
                    sidebar: true
                }
            }
        },
        methods: {
            handleSidebar(status) {
                if ( ! this.$store.state.layout.sidebar ) {
                    this.display.sidebar = false;
                    return;
                }

                this.display.sidebar = status;
            }
        },
        mounted() {
        }
    }
</script>  