<template>
    <div class="relative flex flex-col">
        <v-spa-loader v-if="$store.state.calendar.loading.events || $store.state.calendar.loading.categories || $store.state.calendar.loading.collaborators"></v-spa-loader>
        <template v-if="$store.state.account">
            <div class="px-2">
                <v-controls :loading="false"></v-controls>
                <div class="flex flex-wrap">
                    <v-categories :loading="false"></v-categories>
                    <v-collaborators :loading="false"></v-collaborators>
                </div>
                <v-events class="pt-2" :loading="false"></v-events>
            </div>
        </template>
    </div>
</template>

<script>
    import VControls from './Sidebar/Controls';
    import VCategories from './Sidebar/Categories';
    import VCollaborators from './Sidebar/Collaborators';
    import VEvents from './Sidebar/Events';

    export default {
        components: {
            VControls,
            VCollaborators,
            VEvents,
            VCategories,
        },
        data() {
            return {
                quickActions: false
            }
        },
        computed: {

        }
    }
</script>
