/**
 * Getting next Moment matching interval constraint.
 * @param {string|Object} date Date to check. Can be string, moment or Date.
 * @param {Object} interval Interval to check. Must have type and value properties
 * @param {Number} interval.value Interval value.
 * @param {String} interval.type Interval type.
 * @param {String} roundBy If you need to reset values before getting final value. Example: 'hour' to remove minutes, seconds, milliseconds before getting result.
 */
export function getNextMomentMatching(date, interval = { type: 'minute', value: 15 }, roundBy = 'hour') {
    let momentDate = window.moment(date);

    const intervalToAdd = ( Math.ceil( (momentDate[interval.type]() + 1) / interval.value ) ) * interval.value;

    if ( roundBy ) {
        momentDate.startOf(roundBy);
    }

    return momentDate[interval.type](intervalToAdd);
}

export function getShortMonth(date) {
    return window.moment(date).format('MMM');
}

export function getFullYear(date) {
    return window.moment(date).format('YYYY');
}