export default {
    
    setEvents(state, { events })
    {
        state.calendar.events = events;
    },

    storeEvent(state, { event })
    {
        state.calendar.events.push( event );
    },

    updateEvent(state, { index, event })
    {
        state.calendar.events.splice(index, 1, event);
    },

    destroyEvent(state, { index })
    {
        state.calendar.events.splice(index, 1);
    },

}