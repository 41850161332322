export default {
    setCollaboratorsFilter(context, ids = []) {
        return context.commit('setCollaboratorsFilter', ids === null ? null : [...ids]);
    },

    storeCollaboratorsFilter(context, id)
    {
        if (context.state.calendar.filters.collaborators === null) {
            return context.dispatch('setCollaboratorsFilter', [ id ]);
        }

        return context.commit('storeCollaboratorsFilter', id);
    },

    destroyCollaboratorsFilter(context, id)
    {
        if (context.state.calendar.filters.collaborators.length === 1 || context.state.calendar.collaborators.length == 1) {
            return context.dispatch('setCollaboratorsFilter', null);
        }
        
        if (context.state.calendar.filters.collaborators.length === 0) {
            const ids = context.state.calendar.collaborators.reduce((reducer, coll) => coll.id == id ? reducer : reducer.concat(coll.id), []);
            return context.dispatch('setCollaboratorsFilter', ids);
        }

        return context.commit('destroyCollaboratorsFilter', context.state.calendar.filters.collaborators.findIndex(collId => collId == id));
    },
}