import { isDateInRange } from '../../helpers/dates';

export default {
    /**
     * Setting default view for connected user in localStorage.
     * @param {Object} context
     * @param {String} token
     * @return {?String} App default view for user.
     */
    setDefaultViewToken(context, token)
    {
        return context.commit('setDefaultViewToken', token);
    },

    setCalendarView(context, view)
    {
        if (view === context.state.calendar.view) {
            return view;
        }

        context.commit('setCalendarView', view);
        return context.dispatch('setDefaultViewToken', view);
    },

    /**
     * Setting calendar title based on given date.  
     * @param {Object} context 
     * @param {Date} date Calendar starting date.
     */
    setCalendarTitleFromDate(context, date)
    {
        const title = moment(date).format('MMMM YYYY');
        
        return context.dispatch('setCalendarTitle', title);
    },

    /**
     * Setting calendar title based on given string.  
     * @param {Object} context 
     * @param {String} title
     */
    setCalendarTitle(context, title)
    {
        return context.commit('setCalendarTitle', title.capitalizeWords());
    },
    
    /**
     * Setting calendar isToday attributes from given dates range
     * @param {Object} context 
     * @param {Object} dates
     * @param {Date} dates.start 
     * @param {Date} dates.end
     */
    setCalendarIsTodayFromDatesRange(context, dates)
    {
        const { start, end } = dates;
        
        return context.commit('setCalendarIsToday', isDateInRange(new Date, start, end));
    },

    /**
     * Setting calendar limits.
     * @param {Object} state
     * @param {object} limits
     * @param {Date} limits.start
     * @param {Date} limits.end
     */
    setCalendarLimitDates(context, limits)
    {
        return context.commit('setCalendarLimitDates', limits);
    },
}