export default {
    setCategoriesFilter(context, ids = []) {
        return context.commit('setCategoriesFilter', ids === null ? null : [...ids]);
    },

    storeCategoriesFilter(context, id)
    {
        if (context.state.calendar.filters.categories === null) {
            return context.dispatch('setCategoriesFilter', [ id ]);
        }

        return context.commit('storeCategoriesFilter', id);
    },

    destroyCategoriesFilter(context, id)
    {
        const { filters: { categories: categoryFilters }, categories } = context.state.calendar;

        if (categoryFilters?.length === 1 || categories.length == 1) {
            return context.dispatch('setCategoriesFilter', null);
        }
        
        if (!categoryFilters?.length) {
            const ids = context.state.calendar.categories.reduce((reducer, cat) => cat.id == id ? reducer : reducer.concat(cat.id), []);
            return context.dispatch('setCategoriesFilter', ids);
        }

        return context.commit('destroyCategoriesFilter', context.getters.findCategoriesFilterIndex(id));
    },
}