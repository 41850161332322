import BaseGetters from './getters/base'
import HeadersGetters from './getters/headers'
import CategoriesGetters from "./getters/categories";
import CollaboratorsGetters from "./getters/collaborators";
import EventsGetters from "./getters/events";
import FiltersGetters from './getters/filters';
import TailwindGetters from './getters/tailwind';
import CalendarGetters from './getters/calendar';
import ScreenGetters from './getters/screen';
import ModalGetters from './getters/modal';

export default {
    ...BaseGetters,
    ...HeadersGetters,
    ...CategoriesGetters,
    ...CollaboratorsGetters,
    ...EventsGetters,
    ...FiltersGetters,
    ...TailwindGetters,
    ...CalendarGetters,
    ...ScreenGetters,
    ...ModalGetters,
};