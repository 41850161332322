import { map } from '~base/helpers/objects';
import { isDateInRange } from '~base/helpers/dates'

export default {
    /**
     * Telling if app has a default view for user.
     * @param {Object} state App state.
     * @param {Object} getters App getters.
     * @return {Boolean}
     */
    hasDefaultViewToken: (state, getters) => getters.getDefaultViewToken ? true : false,

    /**
     * Getting app default view for user from localStorage.
     * @param {Object} state App state.
     * @param {Object} getters App getters.
     * @return {?String} App default view for user.
     */
    getDefaultViewToken: (state, getters) => getters.hasUser ? localStorage.getItem(`${state.user.id}_default_view`) : null,

    /**
     * Telling if given token is same as the one stored in localStorage.
     * @param {Object} state App state.
     * @param {Object} getters App getters.
     * @param {String} token Token to compare to.
     * @return {Boolean}
     */
    isTokenSameAsDefaultView: (state, getters) => token => getters.getDefaultViewToken === token,

    isMonthView: state => state.calendar.view === 'dayGridMonth',
    
    isWeekView: state => state.calendar.view === 'timeGridWeek',

    isDayView: state => state.calendar.view === 'timeGridDay',

    /**
     * Transform calendar limits to string dates
     */
    calendarLimitsToString: state => map( state.calendar.limits, date => date.format('YYYY-MM-DD') ),

    /** 
     * Telling if given date is in calendar limits.
     * @param {Date} date
     * @returns {Boolean}
     */
    isDateInCalendarLimits: state => (date, isStrict = false) => isDateInRange( date, ...Object.values(state.calendar.limits).map(limit => limit.toDate()), isStrict ),

    /**
     * Getting new calendar limits based on given date and respecting state interval.
     * @param {Date} date
     */
    getCalendarNewLimitsBasedOnDate: state => date => {
        const start = date.clone().startOf('day').subtract(state.calendar.interval.value, state.calendar.interval.type);
        const end = start.clone().add(state.calendar.interval.value * 2, state.calendar.interval.type);

        return {
            start,
            end,
        };
    },
}