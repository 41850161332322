
export default {

    /**
     * Setting app default view in localStorage.
     * @param {Object} state App state.
     * @param {String} token View name to set.
     * @return {String} App default view for user.
     */
    setDefaultViewToken(state, token)
    {
        localStorage.setItem(`${state.user.id}_default_view`, token);

        return token;
    },

    setCalendarView(state, view)
    {
        state.calendar.view = view;

        return view;
    },

    /**
     * Setting calendar title.
     * @param {Object} state App state
     * @param {String} title Calendar future title
     * @returns {String} Title.
     */
    setCalendarTitle(state, title)
    {
        state.calendar.title = title;

        return title;
    },
    
    /**
     * Setting calendar isToday attribute.
     * @param {Object} state App state.
     * @param {Boolean} value
     * @returns {Boolean} Value. 
     */
    setCalendarIsToday(state, value)
    {
        state.calendar.isToday = value;

        return value;
    },

    /**
     * Setting calendar limits.
     * @param {Object} state State
     * @param {object} limits Dates Limit
     * @param {Date} limits.start Start limit
     * @param {Date} limits.end End limit
     */
    setCalendarLimitDates(state, limits)
    {
        state.calendar.limits = limits;

        return limits;
    }

}