import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import StoreCreator from '@deegital/trustup-spa-js-assets/store';

const store = new StoreCreator({
    state,
    mutations,
    getters,
    actions
}).merge();

export default store;