<template>
    <div>
        <portal to="modals">
            <div v-for="modal in modals" :key="modal.name">
                <component :is="modal.component" v-bind="modal.props">
                </component>
            </div>
        </portal>
    </div>
</template>

<script>

export default {
    data() {
        return {
            rendererModals: [],
        }
    },
    computed: {
        modals() {
            return this.rendererModals.map( ({name, path, props}) => {
                return {
                    name,
                    component: () => import(/* webpackChunkName: "js/modals/[request]" */ `@components/${path}`),
                    props,
                }
            });
        },
    },
    created()
    {
        this.rendererModals = this.$modalRenderer.modals;
    },
}
</script>