export default class Swipe {
    /**
     * Initializing swipe listeners for given HTML element.
     * @param {Node} el HTML element where swipe should be watched.
     * @param {Number} minTranslation Minimal movement distance to be consisidered as swipe.
     * @param {Number} maxDuration Maximal duration for a movement to be considered as swipe.
     */
    constructor( el, minTranslation = 50, maxDuration = 350 ) 
    {
        this.el = el;
        this.minTranslation = minTranslation;
        this.maxDuration = maxDuration;
        this.resetData();
        this.listeners = {
            start: null,
            end: null,
        }
        this.addListeners();
    }

    resetData()
    {
        this.movement = {
            startX: null,
            endX: null,
            translationX: null,
            startY: null,
            endY: null,
            translationY: null,
            translation: null,
            startTime: null,
            endTime: null,
            duration: null,
            type: null,
        };

        this.events = [];

        return this;
    }

    /**
     * Adding listeners to HTML element.
     * @chainable
     * @returns {Swipe}
     */
    addListeners() 
    {
        this.addStartListener()
            .addEndListener()
    }

    /**
     * Adding listener to catch when movement starts.
     * @chainable
     * @returns {Swipe}
     */
    addStartListener()
    {
        this.listeners.start = e => this.handleMovementStart(e);
        this.el.addEventListener('touchstart', this.listeners.start);

        return this;
    }

    /**
     * Handling movement start.
     * @param {Object} e Touchstart native event.
     * @chainable
     * @returns {Swipe}
     */
    handleMovementStart(e)
    {
        this.events.push(e);
        if (this.isMovementWithOneFingerOnly()) {
            this.setMovementStartOptions(e);
        }
    }

    /**
     * Adding listener to catch when movement stops.
     * @chainable
     * @returns {Swipe}
     */
    addEndListener()
    {
        this.listeners.end = e => this.handleMovementStop(e);
        this.el.addEventListener('touchend', this.listeners.end);

        return this;
    }

    /**
     * Handling movement end.
     * @param {Object} e Touchend event.
     * @chainable
     * @returns {Swipe}
     */
    handleMovementStop(e)
    {
        if (this.isMovementWithOneFingerOnly()) {
            this.setMovementStopOptions(e)
                .setMovementDuration()
                .setMovementTranslation();
            
            if (this.isMovementValidSwipe())
            {
                this.emitEvent();
            }
        }

        return this.resetData();
    }

    isMovementWithOneFingerOnly()
    {
        return this.events.length === 1;
    }

    /**
     * Setting movement start position and start time.
     * @param {Object} e Touchstart event.
     * @chainable
     * @returns {Swipe}
     */
    setMovementStartOptions(e)
    {
        this.movement.startX = e.changedTouches[0].screenX;
        this.movement.startY = e.changedTouches[0].screenY;
        this.movement.startTime = new Date().getTime();

        return this;
    }

    /**
     * Setting movement stop position and stop time.
     * @param {Object} e Touchend event.
     * @chainable
     * @returns {Swipe}
     */
    setMovementStopOptions(e)
    {
        this.movement.endX = e.changedTouches[0].screenX;
        this.movement.endY = e.changedTouches[0].screenY;
        this.movement.endTime = new Date().getTime();

        return this;
    }

    /**
     * Setting movement duration.
     * @chainable
     * @returns {Swipe}
     */ 
    setMovementDuration()
    {
        this.movement.duration = this.movement.endTime - this.movement.startTime;

        return this;
    }

    /**
     * Saying if movement duration was short enough to be considered as swipe.
     * @returns {Boolean}
     */
    isMovementDurationShortEnough()
    {
        return this.movement.duration <= this.maxDuration;
    }

    /**
     * Setting movement translation concerning X-axis.
     * @chainable
     * @returns {Swipe}
     */
    setMovementTranslationX()
    {
        this.movement.translationX = Math.max(this.movement.startX, this.movement.endX) - Math.min(this.movement.startX, this.movement.endX);

        return this;
    }

    /**
     * Setting movement translation concerning Y-axis.
     * @chainable
     * @returns {Swipe}
     */
    setMovementTranslationY()
    {
        this.movement.translationY = Math.max(this.movement.startY, this.movement.endY) - Math.min(this.movement.startY, this.movement.endY);

        return this;
    }

    /**
     * Setting biggest axis-based translation as movement translation and setting movement type.
     * @chainable
     * @returns {Swipe}
     */
    setMovementTranslation()
    {
        this.setMovementTranslationX()
            .setMovementTranslationY();
        
        const isX = this.movement.translationX > this.movement.translationY;
        this.movement.translation = isX ? this.movement.translationX : this.movement.translationY;
        
        return this.setMovementType(isX);
    }

    /**
     * Setting movement type. Bsically setting swipe direction as ["up"|"down"|"left"|"right"].
     * @param {Boolean} isX Is concerning x-axis.
     */
    setMovementType(isX)
    {
        this.movement.type = isX ? (this.movement.startX < this.movement.endX ? 'right' : 'left') : (this.movement.startY < this.movement.endY ? 'up' : 'down');

        return this;
    }

    /**
     * Telling if movement translation was big enough to be considered as swipe.
     * @returns {Boolean}
     */
    isMovementTranslationBigEnough()
    {
        return this.movement.translation >= this.minTranslation; 
    }

    /**
     * Telling if movement is considered as swipe.
     * @returns {Boolean}
     */
    isMovementValidSwipe()
    {
        return this.isMovementDurationShortEnough() && this.isMovementTranslationBigEnough();
    }

    /**
     * Emitting js event named ["swipeUp"|"swipeDown"|"swipeLeft"|"swipeRight"] based on movement direction.
     * @chainable
     * @returns {Swipe}
     */
    emitEvent()
    {
        let eventName = 'swipe' + this.capitalize(this.movement.type);
        this.el.dispatchEvent(new Event(eventName));

        return this;
    }

    /**
     * Capitilizing given word.
     * @param {String} word Word to transform.
     * @returns {String}
     */
    capitalize(word)
    {
        return word[0].toUpperCase() + word.slice(1);
    }

    /**
     * Removing movement listeners for element.
     * @chainable
     * @returns {Swipe}
     */
    removeListeners()
    {
        return this.removeStartListener()
            .removeStopListener();
    }

    /**
     * Removing movement start listeners for element.
     * @chainable
     * @returns {Swipe}
     */
    removeStartListener()
    {
        this.el.removeEventListener('touchstart', this.listeners.start);

        return this;
    }

    /**
     * Removing movement stop listeners for element.
     * @chainable
     * @returns {Swipe}
     */
    removeStopListener()
    {
        this.el.removeEventListener('touchend', this.listeners.end);

        return this;
    }

}