import axios from 'axios';

export default {
    getData(context, {url, urlParams = null, key, data = {}, method = 'GET', extras = {}, headers = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data,
                headers: headers
            })
            .then((response) => {
                resolve(response.data[key]);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    setVariableInTitle(context, {variable, value})
    {
        if ( document.title.includes('{'+variable+'}') ) {
            document.title = document.title.replace('{'+variable+'}', value);
        };
    },
}