<template>
    <div
        @keyup.esc="$emit('cancel')" 
        @keyup.enter="$emit('save')"
        tabindex="0"
    >
        <v-spa-mixed-modal
            :name="name"
            height="auto"
            :width="500"
            :scrollable="true"
            :show-close-icon="false"
            @before-open="$emit('before-open', $event)"
            @opened="opened"
            @before-close="$emit('before-close', $event)"
            @closed="closed"
        >
            <div class="flex flex-wrap items-center justify-end pb-4">
                <div class="flex-auto hidden text-lg font-bold text-center text-gray-700 md:block">{{ title }}</div>
                <div class="flex items-center justify-center w-10 h-10 transition duration-200 rounded-full cursor-pointer md:hidden hover:bg-gray-200" v-if="isEdit" @click="$emit('delete')">
                    <i class="text-gray-600 fas fa-trash-alt"></i>
                </div>
                <div 
                    class="flex items-center justify-center w-10 h-10 -mr-4 transition duration-200 rounded-full cursor-pointer hover:bg-gray-200" 
                    @click.prevent="$emit('cancel')"
                >
                    <i class="text-gray-600 fas fa-times"></i>
                </div>
            </div>
            <div class="flex flex-auto w-full">
                <div class="w-full">
                    <div class="mb-6 text-lg font-bold text-gray-700 md:hidden">{{ title }}</div>
                    <slot></slot>     
                    <div class="flex flex-wrap mt-6 -mx-2">
                        <div class="hidden px-2 md:block md:w-1/2">
                            <v-spa-loading-button 
                                class="w-full" 
                                :loading="cancelling"
                                @click.native.prevent="$emit('cancel')"
                                :color="$spa.css.button.color.gray"
                            >
                                {{ $t('form.buttons.cancel') }}
                            </v-spa-loading-button>
                        </div>
                        <div class="w-full px-2 md:w-1/2">
                            <v-spa-loading-button
                                class="w-full" 
                                :loading="saving" 
                                @click.native.prevent="$emit('save')"
                            >
                                {{ isEdit ? $t('form.buttons.edit') : $t('form.buttons.create') }}
                            </v-spa-loading-button>
                        </div>
                        <div v-if="isEdit" class="hidden mt-2 md:block md:w-1/2">
                            <v-spa-loading-button
                                :color="$spa.css.button.color.delete_link" 
                                class="w-full" 
                                :loading="deleting"
                                @click.native.prevent="$emit('delete')" 
                            > 
                                {{ $t('form.buttons.delete') }} 
                            </v-spa-loading-button>
                        </div>
                    </div>
                </div>
            </div>
        </v-spa-mixed-modal>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                required: true,
                type: String,
            },
            title: {
                required: false,
                type: String,
                default() {
                    return this.name;
                }
            },
            isEdit: {
                required: false,
                type: Boolean,
                default() {
                    return false;
                }
            },
            saving: {
                required: false,
                type: Boolean,
                default() {
                    return false;
                }
            },
            cancelling: {
                required: false,
                type: Boolean,
                default() {
                    return false;
                }
            },
            deleting: {
                required: false,
                type: Boolean,
                default() {
                    return false;
                }
            },

        },
        methods: {
            opened(e)
            {
                return this.toggleModalState()
                    .then(() => this.$emit('opened', e));
            },
            closed(e)
            {
                return this.toggleModalState()
                    .then(() => this.$emit('closed', e));
            },
            toggleModalState()
            {
                return this.$store.dispatch('toggleModal', this.name);
            }
        },
    }

</script>

<style>

</style>