export default {
    setCategories(context) {
        return context.dispatch('getAccountData', {
            url: '/api/categories',
        })
        .then(data => {
            let { data: categories } = data;
            context.commit('setCategories', { categories });
            return data;
        } );
    },

    refreshCategories(context) {
        return context.dispatch('setCategories');
    },

    storeCategory(context, { category }) {
        return context.dispatch('postAccountData', {
            url: `/api/categories`,
            data: category,
        })
        .then(data => {
            let { data: category } = data;
            context.commit('storeCategory', {
                category,
            });
            return data;
        });
    },

    updateCategory(context, { uuid, category }) {
        return context.dispatch('updateAccountData', {
            url: `/api/categories/${uuid}`,
            data: category
        })
        .then(data => {
            let { data: category } = data;
            context.commit('updateCategory', {
                index: context.getters.findCategoryIndex( category ),
                category: category,
            });
            return data;
        });
    },

    destroyCategory(context, { uuid }) {
        return context.dispatch('deleteAccountData', {
            url: `/api/categories/${uuid}`,
        })
        .then(data => {
            context.commit('destroyCategory', {
                index : context.getters.findCategoryIndexByUuid(uuid)
            });
            return data;
        });
    },
}