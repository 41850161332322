
import AccountActions from "./actions/login/accounts";
import AuthActions from "./actions/login/auth";
import ProfessionalActions from "./actions/login/professionals";
import baseActions from "./actions/login/base";
import RequestActions from "./actions/request";
import CategoriesActions from "./actions/categories";
import CollaboratorsActions from "./actions/collaborators";
import EventsActions from "./actions/events";
import FiltersActions from './actions/filters';
import CalendarActions from './actions/calendar';
import ScreenActions from './actions/screen';
import ModalActions from './actions/modal';
 

export default {
    ...AccountActions,
    ...AuthActions,
    ...ProfessionalActions,
    ...baseActions,
    ...RequestActions,
    ...CategoriesActions,
    ...CollaboratorsActions,
    ...EventsActions,
    ...FiltersActions,
    ...CalendarActions,
    ...ScreenActions,
    ...ModalActions,
}
