import AccountsIndex from "./components/Accounts/Index";
import AccountsBase from "./components/Accounts/Base";
import Calendar from './components/Calendar';

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

const routes = [

    generator.create({
        component: AccountsBase,
        redirect: {
            name: 'account.calendar'
        },
        children: [
            generator.create({
                path: 'calendar',
                name: 'account.calendar',
                component: Calendar
            })
            .layout({ container: false })
            .protected()
            .get()
        ]
    })
    .layout({ container: false })
    .protected()
    .account()
    .title('Agenda - TrustUp Pro')
    .get(),
    
    generator.create({
        component: AccountsIndex,
    })
    .accounts()
    .get(),

    generator.create()
        .homepage()
        .get(),

];

export default routes;